import styled from "styled-components"
import { animated } from "react-spring"

export const MobileBlogCardWrapper = styled.div`
  height: 400px;

  @media (min-width: 800px) {
    display: none;
  }
`

export const MobileBlogCardImage = styled.div`
  height: 280px;
`

export const MobileBlogCardBody = styled(animated.div)`
  padding: 20px;
  max-width: calc(100% - 40px);
`
export const MobileBlogCardLabel = styled.span`
  font-family: "HelveticaNeue Condensed";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 6px;
  text-align: left;
  color: #adadad;
`

export const MobileBlogCardHeading = styled.h3`
  margin: 0;
  font-family: "HelveticaNeue Condensed";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 6px;
  text-align: left;
`

export const DesktopBlogCardWrapper = styled.article`
  display: none;

  @media (min-width: 800px) {
    display: block;
    height: 100%;
    width: 100%;
    max-width: 450px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0 19px 60px 0 rgba(0, 0, 0, 0.29);
    }
  }
`

export const DesktopBlogCardImage = styled(animated.div)`
  width: 100%;
`
export const DesktopBlogCardBody = styled(animated.div)`
  padding: 20px;
  width: 100%;
`
export const DesktopBlogCardLabel = styled.span`
  font-family: "HelveticaNeue Condensed";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 2;
  letter-spacing: 6px;
  text-align: left;
  color: #adadad;
`
export const DesktopBlogCardReadNow = styled(animated.div)`
  width: 100%;
  max-width: 80%;
  font-family: "HelveticaNeue Condensed";
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  letter-spacing: 3px;
  text-align: left;
`
export const DesktopBlogCardReadNowIcon = styled(animated.div)`
  height: 50px;
  width: 50px;

  & > img {
    height: 40px;
    margin-bottom: -10px;
    margin-left: -5px;
  }
  & > div {
    margin: 0;
    padding: 0;
  }
`

export const DesktopBlogCardHeading = styled.h3`
  margin: 0;
  max-width: 80%;
  font-family: "HelveticaNeue Condensed";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 2;
  letter-spacing: 6px;
  text-align: left;
`
