import React from "react"
import { graphql } from "gatsby"

// helpers
import i18n from "../../config/i18n"

// styled components
import {
  BlogListContainer,
  Heading,
  CardWrapper,
} from "../styles/pages-styles/blog-styles"

// components
import { SEO } from "../components/Seo"
import { BlogCard } from "../components/BlogList/BlogCard"

/**
 *
 * @author Matt Hamers
 */
export const Blog = ({ data, pageContext: { locale }, location }) => {
  const staticText = i18n[locale].blog
  const blogs = data.blog_posts.edges

  return (
    <>
      <SEO
        pathname={location.pathname}
        locale={locale}
        desc={staticText.metaDescription}
        title={staticText.metaTitle}
      />
      <Heading>{staticText.heading}</Heading>
      <BlogListContainer>
        {blogs &&
          blogs.map((blog, index) => (
            <CardWrapper>
              <BlogCard key={index} blog={blog} />
            </CardWrapper>
          ))}
      </BlogListContainer>
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query BlogQuery($locale: String!) {
    blog_posts: allPrismicBlogPost(
      filter: { lang: { eq: $locale } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            date
            card_image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 450) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title {
              html
              text
            }
            reading_time
            author {
              document {
                data {
                  first_name
                  last_name
                  about_person_short
                  profile_picture {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
