import React, { useState } from "react"
import { useSpring } from "react-spring"
import { StyledBackgroundComponent } from "../../../components/Primitives/BackgroundImage"
import i18n from "../../../../config/i18n"
import { LocaleContext } from "../../../components/Layout"
import glasses from "../../../images/glasses-icon-black.svg"
import { LocalizedLink } from "../../../components/Primitives/LocalizedLink"
import {
  MobileBlogCardWrapper,
  MobileBlogCardImage,
  MobileBlogCardBody,
  MobileBlogCardHeading,
  MobileBlogCardLabel,
  DesktopBlogCardWrapper,
  DesktopBlogCardImage,
  DesktopBlogCardBody,
  DesktopBlogCardHeading,
  DesktopBlogCardLabel,
  DesktopBlogCardReadNow,
  DesktopBlogCardReadNowIcon,
} from "./blogcard-styles"

export const BlogCard = ({ blog }) => {
  return (
    <>
      <BlogCardDesktop blog={blog} />
      <BlogCardMobile blog={blog} />
    </>
  )
}

export const BlogCardMobile = ({ blog: { node } }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].stories
  const { title, card_image } = node.data

  return (
    <MobileBlogCardWrapper>
      <LocalizedLink to={`/${node.uid}`}>
        <StyledBackgroundComponent
          tag="div"
          gradientOverlay
          imageData={card_image.localFile.childImageSharp.fluid}
        >
          <MobileBlogCardImage />
        </StyledBackgroundComponent>
        <MobileBlogCardBody>
          <MobileBlogCardLabel>{staticText.label} </MobileBlogCardLabel>
          <MobileBlogCardHeading>{title && title.text}</MobileBlogCardHeading>
        </MobileBlogCardBody>
      </LocalizedLink>
    </MobileBlogCardWrapper>
  )
}

export const BlogCardDesktop = ({ blog: { node } }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].stories

  const { title, card_image } = node.data
  const [toggle, setToggle] = useState(false)

  const imageAnimation = useSpring({
    from: { height: toggle ? "400px" : "400px" },
    to: { height: toggle ? "330px" : "400px" },
  })

  const readMoreWrapperAnimation = useSpring({
    from: { height: toggle ? "0px" : "70px" },
    to: { height: toggle ? "70px" : "0px" },
  })

  const readMoreIconAnimation = useSpring({
    delay: toggle ? 200 : 0,
    from: { opacity: toggle ? 0 : 1 },
    to: { opacity: toggle ? 1 : 0 },
  })

  return (
    <DesktopBlogCardWrapper
      onMouseEnter={() => setToggle(true)}
      onMouseLeave={() => setToggle(false)}
    >
      <LocalizedLink to={`/${node.uid}`}>
        <StyledBackgroundComponent
          tag="div"
          gradientOverlay
          imageData={card_image.localFile.childImageSharp.fluid}
        >
          <DesktopBlogCardImage style={imageAnimation} />
        </StyledBackgroundComponent>

        <DesktopBlogCardBody>
          <DesktopBlogCardReadNow style={readMoreWrapperAnimation}>
            <DesktopBlogCardReadNowIcon style={readMoreIconAnimation}>
              <img src={glasses} alt="read now" />
              <div dangerouslySetInnerHTML={{ __html: staticText.readNow }} />
            </DesktopBlogCardReadNowIcon>
          </DesktopBlogCardReadNow>
          <DesktopBlogCardLabel>{staticText.label} </DesktopBlogCardLabel>
          <DesktopBlogCardHeading>{title && title.text}</DesktopBlogCardHeading>
        </DesktopBlogCardBody>
      </LocalizedLink>
    </DesktopBlogCardWrapper>
  )
}

export default BlogCard
