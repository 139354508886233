import styled from "styled-components"

export const Heading = styled.h1`
  margin: 0 20px;
  margin-top: 100px;
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 12.5px;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: 800px) {
    margin: 0 10%;
    margin-top: 200px;
    font-size: 2rem;
  }
`

export const BlogListContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 20px;

  @media (min-width: 800px) {
    margin: 0 8%;
  }
`
export const CardWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;

  @media (min-width: 800px) {
    margin: 40px 0;
  }
`
